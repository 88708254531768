import PTZTokens from '@petz/ds-tokens/petz';
import { Component, Element, Host, Prop, h } from '@stencil/core';
import { hexToRgb } from '../../../../utils/utils';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { PTZHeaderDepartmentsDropdownTypes } from './types/ptz-header-departments-dropdown.types'
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { isOnIframe } from '../../../../utils/utils';

@Component({
  tag: `ptz-header-departments-dropdown`,
  styleUrl: 'ptz-header-departments-dropdown.scss',
  shadow: false,
})
export class PTZHeaderDepartmentsDropdown {
  /** Define a cor a ser utilizada no menu */
  @Prop() color?: string = '';
  /** Define o conteúdo do menu do header */
  @Prop() categories: PTZHeaderTypes.MenuDepartmentsCategories[];
  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  @Element() el: HTMLElement;

  private handleAnalyticsTracking(props: PTZHeaderDepartmentsDropdownTypes.handleAnalyticsTracking) {
    const completeUrl = props.link_url.split('/').filter(Boolean);
    const department = completeUrl[2]?.replace(/-/g, ' ') || null;
    const category = completeUrl[3]?.replace(/-/g, ' ') || null;
    const subCategory = completeUrl[4]?.replace(/-/g, ' ');

    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
      link_text: props.link_text,
      link_url: props.link_url,
      department,
      category,
      subcategory: subCategory|| null
    })
  }

  componentDidLoad() {
    const colorBlack = PTZTokens.colorNeutralDarkerAccent;
    this.el.style.setProperty('--header-department-color', this.color || colorBlack);
    this.el.style.setProperty('--header-department-color-rgb', hexToRgb(this.color || colorBlack));
  }

  render() {

    const menuDropdownInside = (departments: any, categoryToken?: string) => {
      return(
      <ul class="menu-dropdown-item-sublist">
        {departments.map(el => menuCategories(el.id, el.token, el.name, el.subCategories))}
        {categoryToken && <li class="menu-dropdown-item">
          <a
            href={categoryToken}
            class="menu-dropdown-item-link"
            { ...(isOnIframe() ? { target: "_parent" } : {}) }
            onClick={() => this.handleAnalyticsTracking({ link_text: 'Ver todos', link_url: categoryToken })}
          >
            <span class="menu-dropdown-item-see-all">Ver todos</span>
          </a>
        </li>}
      </ul>
    )};

    const menuCategories = (id: number, href: string, label: string, subCategories: any) => {
      if(!href) {
        return (
          <li key={id} class="menu-dropdown-item">
            <div class="menu-dropdown-item-link">
              <span class="menu-dropdown-item-label">{label}</span>
              {subCategories?.length > 0 && <ptz-icon brand="ptz" variant="line" name="petz-chevron-right" size="md"></ptz-icon>}
            </div>
            {subCategories?.length > 0 && menuDropdownInside(label, subCategories)}
          </li>
        )
      }

      return(
      <li key={id} class="menu-dropdown-item">
          <a
            href={href}
            class="menu-dropdown-item-link"
            { ...(isOnIframe() ? { target: "_parent" } : {}) }
            onClick={() => this.handleAnalyticsTracking({ link_text: label, link_url: href })}
          >
          <span class="menu-dropdown-item-label">{label}</span>
          {subCategories?.length > 0 && <ptz-icon brand="ptz" variant="line" name="petz-chevron-right" size="md"></ptz-icon>}
        </a>
        {subCategories?.length > 0 && menuDropdownInside(subCategories, href)}
      </li>
    )};

    return (
      <Host>
        <div class="menu-dropdown">
          <ul class="menu-dropdown-list">
            {this.categories && this.categories.map(el => menuCategories(el.id, el.token, el.name, el.subCategories))}
          </ul>
        </div>
      </Host>
    );
  }
}
