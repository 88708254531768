ptz-header-department-item {
  --header-department-color: $petz-color-neutral-black;

  .menu-departments-item {
    padding: $petz-spacing-md;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $petz-spacing-xs;
    width: fit-content;
    transition: all .1s ease-in .1s;
    text-decoration: none;
    border-bottom: $petz-border-md solid transparent;
    cursor: pointer;

    &-overlay {
      position: fixed;
      left: 0;
      right: 0;
      background-color: $petz-color-neutral-black;
      opacity: 0;
      height: 100vh;
      z-index: -1;
      pointer-events: none;
      transition: opacity 0.6s ease-out;
      -webkit-transition: opacity 0.6s ease-out; /* Safari */
    }

    &-container {
      position: relative;

      ptz-header-departments-dropdown {
        display: none;
      }

      &:hover {
        transition: all .1s ease-out 0s;

        & .menu-departments-item {
          border-bottom-color: var(--header-department-color);
          text-decoration: none;
        }

        & .menu-departments-item-icon-animated {
          transform: translateX(0px) scale(100%) rotate(0deg);
          transition: all .4s cubic-bezier(.42, .97, .52, 1.49);
          width: $petz-spacing-lg;
        }

        & .menu-departments-item-text {
          color: var(--header-department-color);
        }

        ptz-header-departments-dropdown {
          display: block;
          top: 100%;
        }

      }

      &-dropdown:hover {
        .menu-departments-item-error {
          display: flex;
          top: 100%;
        }
        .menu-departments-item-overlay {
          opacity: $petz-opacity-lg;
        }
      }
    }

    &-text {
      font-size: $petz-font-size-xs;
      font-weight: $petz-font-weight-bold;
      color: $petz-color-neutral-black;
      white-space: nowrap;

      &-color {
        color: var(--header-department-color);
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $petz-spacing-lg;
      width: $petz-spacing-lg;

      ptz-icon {
        display: flex;
      }

      &-animated {
        overflow: hidden;
        transform: translateX(20px) scale(0%) rotate(-120deg);
        width: 0;
        transition: all .3s ease-out;
      }
    }

    &-static {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    &-error {
      position: absolute;
      display: none;
      align-items: center;
      justify-content: center;
      width: 20.125rem;
      height: 15.375rem;
      background-color: $petz-color-neutral-white;
    }

    @media (max-width: $petz-breakpoint-xl) {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    @media  (min-height: 750px) and (max-height: 960px){
      padding: 1.25rem;
    }

    @media (max-height: 750px) {
      padding: $petz-spacing-xs 1.25rem;
    }
  }
}
