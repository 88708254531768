ptz-header-departments-dropdown {
    --header-department-color: $petz-color-neutral-black;
    --header-department-color-rgb: rgb(29, 29, 29);
    $color-hex: var(--header-department-color);
    $color-rgb: var(--header-department-color-rgb);
    $listWidth: 18.125rem;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .menu-dropdown {
        position: relative;

        &-list {
            box-sizing: unset;
            background-color: $petz-color-neutral-white;
            min-width: $listWidth;
            width: fit-content;
            padding: $petz-spacing-sm;
            position: absolute;
            z-index: 1;
        }

        &-item {
            &-link {
                align-items: center;
                border-radius: $petz-rounded-max;
                display: flex;
                justify-content: space-between;
                padding: $petz-spacing-xs $petz-spacing-md;
                text-decoration: none;
                cursor: pointer;

                ptz-icon {
                    color: $petz-color-neutral-mid;
                    display: flex;
                }
            }

            &-sublist {
                box-sizing: unset;
                background-color: $petz-color-neutral-white;
                left: 0;
                margin: 0;
                min-height: 100%;
                min-width: $listWidth;
                width: fit-content;
                opacity: 0;
                padding-left: $listWidth + $petz-spacing-sm;
                padding-right: $petz-spacing-sm;
                padding-bottom: $petz-spacing-sm;
                padding-top: $petz-spacing-sm;
                pointer-events: none;
                position: absolute;
                top: 0;
                visibility: hidden;
                z-index: -1;
            }

            &-label {
                color: $petz-color-neutral-black;
            }

            &-see-all {
                color: $petz-color-primary-brand;
                font-weight: $petz-font-weight-bold;
            }

            &:hover {
                &>.menu-dropdown-item-link {
                    background: rgba($color-rgb, $petz-opacity-xs);
                    text-decoration: none;

                    &>ptz-icon {
                        color: $color-hex;
                    }

                    &>.menu-dropdown-item-label, &>.menu-dropdown-item-see-all {
                        color: $color-hex;
                    }
                }

                &>.menu-dropdown-item-sublist {
                    opacity: 1;
                    pointer-events: all;
                    visibility: visible;
                }
            }
        }
    }

    @media (min-height: 750px) and (max-height: 960px) {
        .menu-dropdown-item-label,
        .menu-dropdown-item-see-all {
            font-size: $petz-font-size-2xs;
        }

        .menu-dropdown-item-link {
            padding: $petz-spacing-2xs $petz-spacing-md;
        }

        .menu-dropdown-item-sublist {
            min-height: 95%;
        }
    }

    @media (max-height: 750px) {
        .menu-dropdown-item-label,
        .menu-dropdown-item-see-all {
            font-size: $petz-font-size-3xs;
        }

        .menu-dropdown-list {
            padding: 0.25rem;
        }
        .menu-dropdown-item-link {
            padding: 0.3rem $petz-spacing-md;
        }
    }
}
