import PTZTokens from '@petz/ds-tokens/petz';
import { Component, Element, Event, EventEmitter, Host, Prop, State, h } from '@stencil/core';
import lottie from 'lottie-web/build/player/lottie_light';
import { PTZHeaderConfig } from '../../types/ptz-header.enums';
import { PTZHeaderTypes } from '../../types/ptz-header.types';
import { isOnIframe } from '../../../../utils/utils';

@Component({
  tag: `ptz-header-department-item`,
  styleUrl: 'ptz-header-department-item.scss',
  shadow: false
})
export class PTZHeaderDepartmentItem {
  /** Define o texto principal do menu */
  @Prop() label: string = '';
  /** Define se o item tem ou não gaveta no menu */
  @Prop() hasDrawer?: boolean = false;
  /** Define se o ícone do menu será animado ou não */
  @Prop() isAnimated?: boolean = false;
  /** Define a url com o ícone exibido no menu */
  @Prop() iconUrl: string = '';
  /** Define a url que fará o redirecionamento */
  @Prop() href?: string = '';
  /** Define a cor a ser utilizada no menu */
  @Prop() color?: string = '';
  /** Define as subcategorias */
  @Prop() categories?: PTZHeaderTypes.MenuDepartmentsCategories[];
  /** Indica se tem erro na chamada do menu */
  @Prop() hasError?: boolean = false;
  /** Método de traqueamento */
  @Prop() analyticsTrackingHandler: (type: PTZHeaderTypes.AnalyticsTrackingKind, options?: PTZHeaderTypes.AnalyticsTracking) => void;

  /** Evento que dispara ao clicar em um item */
  @Event({
    eventName: 'menuItemClick',
    composed: true,
    cancelable: true,
    bubbles: true,
  }) menuItemClick: EventEmitter<string>;

  private elementRef: HTMLSpanElement;
  @State() private isIconLottie: boolean;
  @Element() el: HTMLElement;

  componentDidLoad() {
    this.isIconLottie = Boolean(this.iconUrl?.includes('json'));
    if (this.isIconLottie) {
      lottie.loadAnimation({
        container: this.elementRef,
        path: this.iconUrl
      });
    }

    this.el.style.setProperty('--header-department-color', this.color || PTZTokens.colorNeutralDarkerAccent);
  }

  handleMenuItemClick() {
    this.analyticsTrackingHandler(PTZHeaderConfig.AnalyticsTrackingKind.HeaderLinkClicked, {
      link_text: this.label,
      link_url: !this.hasDrawer ? this.href : null,
      department: this.label,
      category: null,
      subcategory: null
    })
    this.menuItemClick.emit(this.label)
  }

  render() {
    const hasDropdown = this.categories && this.categories.length > 0 && !this.hasDrawer  && this.isAnimated;
    const containerClasses = {"menu-departments-item-container": true, "menu-departments-item-container-dropdown": hasDropdown}
    const iconClasses = { "menu-departments-item-icon": true, 'menu-departments-item-icon-animated': this.isAnimated };
    const labelClasses = { "menu-departments-item-text": true, 'menu-departments-item-text-color': !this.isAnimated };

    const menuDepartamentsIconFactory = () => {
      if (this.iconUrl) {
        return (
          <span ref={(el: HTMLSpanElement) => (this.elementRef = el)} class={iconClasses}>
            {!this.isIconLottie && <img src={this.iconUrl} alt={this.label} class="menu-departments-item-static" />}
          </span>
        );
      }
    };

    const menuDepartmentsItem = () => {
      if(!this.href || this.hasDrawer) {
        return (
          <div class="menu-departments-item" onClick={() => this.handleMenuItemClick()}>
            {menuDepartamentsIconFactory()}
            <span class={labelClasses}>{this.label}</span>
          </div>
        )
      }
      return (
        <a
          href={this.href}
          class="menu-departments-item"
          { ...(isOnIframe() ? { target: "_parent" } : {}) }
          onClick={() => this.handleMenuItemClick()}
        >
          {menuDepartamentsIconFactory()}
          <span class={labelClasses}>{this.label}</span>
        </a>
      )
    }

    return (
      <Host>
        <div class={containerClasses}>
          {menuDepartmentsItem()}
          {hasDropdown && <ptz-header-departments-dropdown analyticsTrackingHandler={this.analyticsTrackingHandler} color={this.color} categories={this.categories}></ptz-header-departments-dropdown>}
          {this.hasError && !this.hasDrawer && this.isAnimated && <div class="menu-departments-item-error"><ptz-header-error show /></div>}
          <div class="menu-departments-item-overlay"></div>
        </div>
      </Host>
    );
  }
}
